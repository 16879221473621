//Name: YouTubeVideo.js
//Author: Domenic Sesa
//Date Created: 24-08-2024

const YoutubeVideo = ({ url, title }) => {
  return (
    <div className="aspect-video w-full mb-4 md:mb-6">
      <iframe
        src={`${url}?rel=0&modestbranding=1&controls=1&autohide=1&iv_load_policy=3`}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full h-full"
      ></iframe>
    </div>
  );
};

export default YoutubeVideo;
