//Name: Header.js
//Author: Domenic Sesa
//Date Created: 24-08-2024

import React from 'react';

// Scroll Links
import { Link as ScrollLink } from "react-scroll";

// Utils
import { cn } from "@/lib/utils";

// Navigation Component
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

// Tooltip Component
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

//light/dark mode toggle
import { SunIcon, MoonIcon } from '@heroicons/react/solid';

// HeaderButtons array
const HeaderButtons = [
  {
    title: "Home",
    section: "home",
    tooltip: "Go to the home section",
  },
  {
    title: "About",
    section: "about",
    tooltip: "Go to the About me section",
  },
  {
    title: "Projects",
    section: "projects",
    tooltip: "View all my projects",
  },
  {
    title: "Game",
    section: "games",
    tooltip: "View my game, Nightmares in the Sand",
  },
  {
    title: "Other",
    section: "other",
    tooltip: "View 3D prints I have designed",
  },
  {
    title: "Contact",
    section: "contact",
    tooltip: "Contact me and view socials",
  },
];

const Header = ({ isHomeSection, toggleTheme, isDarkMode }) => {
  return (
    <nav className={cn("fixed top-0 left-0 right-0 z-50 flex justify-center items-center py-2", {
      "bg-black text-white": isDarkMode,
      "bg-white text-black": !isDarkMode,
    })}>
      <NavigationMenu className="justify-center w-full">
        <NavigationMenuList className="flex flex-wrap justify-center lg:space-x-6">
          {/* Navigation Links */}
          {HeaderButtons.map((button) => (
            <TooltipProvider key={button.section}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <NavigationMenuItem>
                    <ScrollLink
                      to={button.section}
                      smooth={true}
                      duration={500}
                      spy={true}
                      offset={-50}
                      activeClass="active-link"
                      className={cn(
                        navigationMenuTriggerStyle(),
                        "navigation-link no-underline px-4 py-2 rounded-md transition-all duration-300",
                        {
                          "text-white hover:text-black hover:bg-white": isDarkMode,
                          "text-black hover:text-white hover:bg-black": !isDarkMode,
                        }
                      )}
                    >
                      <p className="xs:text-xs sm:text-xs md:text-xl lg:text-2xl font-extrabold">{button.title}</p>
                    </ScrollLink>
                  </NavigationMenuItem>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{button.tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}

          {/* Light/Dark Mode Toggle */}
          <NavigationMenuItem>
            <button
              onClick={toggleTheme}
              className={cn("ml-4 rounded-full transition-all duration-300 flex items-center justify-center", {
                "bg-white text-black": isDarkMode, 
                "bg-black text-white": !isDarkMode, 
              })}
              className="xs:p-2 xs:h-8 xs:w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 lg:h-14 lg:w-14 xl:h-16 xl:w-16"
            >
              {isDarkMode ? (
                <SunIcon className="h-full w-full" /> 
              ) : (
                <MoonIcon className="h-full w-full" />
              )}
            </button>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </nav>
  );
};

export default Header;
