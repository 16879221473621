import * as React from "react";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";

const MotionButtonCard = motion(React.forwardRef<
  HTMLAnchorElement,
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, ...props }, ref) => (
  <a
    ref={ref}
    className={cn(
      "block rounded-lg border bg-card text-card-foreground shadow-sm",
      className
    )}
    {...props}
  />
)));
MotionButtonCard.displayName = "MotionButtonCard";

const ButtonCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6 text-center", className)}
    {...props}
  />
));
ButtonCardHeader.displayName = "ButtonCardHeader";

const ButtonCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, style, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-4xl font-semibold leading-none tracking-tight text-center",
      className
    )}
    style={{ ...style, fontSize: "24px" }} // Set font size to 24px
    {...props}
  />
));
ButtonCardTitle.displayName = "ButtonCardTitle";


const ButtonCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground text-center", className)}
    {...props}
  />
));
ButtonCardDescription.displayName = "ButtonCardDescription";

const ButtonCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0 text-center", className)} {...props} />
));
ButtonCardContent.displayName = "ButtonCardContent";

const ButtonCardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center justify-center p-6 pt-0", className)}
    {...props}
  />
));
ButtonCardFooter.displayName = "ButtonCardFooter";

const ButtonCardIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center justify-center h-full text-center p-9", className)}
    {...props}
  />
));
ButtonCardIcon.displayName = "ButtonCardIcon";

export {
  MotionButtonCard as ButtonCard,
  ButtonCardHeader,
  ButtonCardFooter,
  ButtonCardTitle,
  ButtonCardDescription,
  ButtonCardContent,
  ButtonCardIcon,
};
