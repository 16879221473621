//Name: wobble-card.tsx

"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "@/lib/utils";

export const WobbleCard = ({
  children,
  containerClassName,
  className,
  imageSrc,
  imageAlt,
}: {
  children?: React.ReactNode;
  containerClassName?: string;
  className?: string;
  imageSrc: string;
  imageAlt: string;
}) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    const { clientX, clientY } = event;
    const rect = event.currentTarget.getBoundingClientRect();
    const x = (clientX - (rect.left + rect.width / 2)) / 20;
    const y = (clientY - (rect.top + rect.height / 2)) / 20;
    setMousePosition({ x, y });
  };
  return (
    <motion.section
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => {
        setIsHovering(false);
        setMousePosition({ x: 0, y: 0 });
      }}
      style={{
        transform: isHovering
          ? `translate3d(${mousePosition.x}px, ${mousePosition.y}px, 0) scale3d(1, 1, 1)`
          : "translate3d(0px, 0px, 0) scale3d(0.9, 0.9, 1)",
        transition: "transform 0.1s ease-out",
      }}
      className={cn(
        "mx-auto w-full overflow-hidden",
        containerClassName
      )}
    >
      <div
        className="relative h-full overflow-hidden"

      >
        <motion.div
          style={{
            transform: isHovering
              ? `translate3d(${-mousePosition.x}px, ${-mousePosition.y}px, 0) scale3d(1, 1, 1)`
              : "translate3d(0px, 0px, 0) scale3d(0.9, 0.9, 1)",
            transition: "transform 0.1s ease-out",
          }}
          className={cn("h-full w-full", className)}
        >

          {children}
        </motion.div>
      </div>
    </motion.section>
  );
};
