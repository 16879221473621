//Name: App.js
//Author: Domenic Sesa
//Date Created: 24-08-2024

import './App.css';

import Home from './mycomponents/Home';

function App() {
  return (
      <div className="App">
        <Home />
      </div>
  );
}

export default App;
