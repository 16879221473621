//Name: ScrollArrow.js
//Author: Domenic Sesa
//Date Created: 24-08-2024

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ArrowSVG from '../images/Arrow.svg';

const ScrollArrow = ({ targetSectionDown, targetSectionUp, isDarkMode }) => {
  const [isBelow, setIsBelow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById(targetSectionDown);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        setIsBelow(sectionTop < windowHeight / 2 + 96);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [targetSectionDown]);

  const handleClick = () => {
    const targetSection = isBelow ? targetSectionUp : targetSectionDown;
    const section = document.getElementById(targetSection);
    if (section) {
      const offset = -50;
      const y = section.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <motion.div
      initial={{ y: -10 }}
      animate={{ y: [0, 10, 0] }}
      transition={{ duration: 3, repeat: Infinity }}
      className="cursor-pointer"
      onClick={handleClick}
      style={{ transform: `rotate(${isBelow ? 180 : 0}deg)` }}
    >
      <motion.img 
        src={ArrowSVG} 
        alt="Scroll Arrow" 
        className={`w-16 h-16 ${!isDarkMode ? 'filter invert' : ''}`} 
        animate={{ rotate: isBelow ? 180 : 0 }}
        transition={{ duration: 0.25 }}
      />
    </motion.div>
  );
};

export default ScrollArrow;
