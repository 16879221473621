import React, { useEffect, useState } from "react";

// Social Media Icons
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import YoutubeIcon from '@mui/icons-material/YouTube';

// Tooltip
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

// Utils
import { cn } from "@/lib/utils"; 

// Icons
const icons = {
  GitHubIcon: GitHubIcon,
  LinkedInIcon: LinkedInIcon,
  YoutubeIcon: YoutubeIcon,
  MailIcon: MailIcon
};

const Footer = ({ isHomeSection, isDarkMode }) => {
  const [socialLinks, setSocialLinks] = useState([]);

  // Fetch social links from config.json
  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {
        setSocialLinks(data.socialLinks);
      })
      .catch((error) => console.error("Error loading config:", error));
  }, []);

  return (
    <div className={cn("fixed bottom-0 w-full z-50 flex justify-center items-center p-4 transition-opacity duration-9000 ease", {
      "bg-black text-white opacity-100": isHomeSection && isDarkMode,
      "bg-white text-black opacity-100": isHomeSection && !isDarkMode,
      "opacity-0": !isHomeSection,
    })}>
      <div className="container mx-auto">
        <nav className="flex justify-center">
          <ul className="flex space-x-4">
            {socialLinks.map((link) => {
              const IconComponent = icons[link.icon];
              return (
                <li key={link.title} className="relative">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <a
                          href={link.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cn("flex items-center justify-center p-2 rounded-md transition-all duration-300", {
                            "bg-transparent hover:bg-white hover:text-black": isDarkMode,
                            "bg-transparent hover:bg-black hover:text-white": !isDarkMode,
                          })}
                          aria-label={link.tooltip}
                        >
                          <IconComponent className="transition-all duration-300" style={{ fontSize: '36px' }} />
                        </a>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{link.tooltip}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Footer;
