//Name: Home.js
//Author: Domenic Sesa
//Date Created: 24-08-2024


import React, { useEffect, useState } from 'react';

// Header and Footer Component
import Header from './Header';
import Footer from './Footer';

// Framer Motion
import { motion } from 'framer-motion';

// Scroll Arrow Component
import ScrollArrow from './ScrollArrow';

// Wobble Card Component
import { WobbleCard } from '@/components/ui/wobble-card';

// Scroll Animation Component
import ScrollAnimation from './ScrollAnimation';

// YouTube Video Component
import YoutubeVideo from './YoutubeVideo';

// Social Media Icons
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';
import YoutubeIcon from '@mui/icons-material/YouTube';

// Card Component
import {
  Card,
  CardContent,
  CardFooter,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

// ButtonCard Component
import {
  ButtonCard,
  ButtonCardContent,
  ButtonCardDescription,
  ButtonCardFooter,
  ButtonCardTitle,
  ButtonCardIcon,
} from '@/components/ui/buttoncard';

// Carousel Component
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';

// Button Component
import { Button } from '@/components/ui/button';

// Tooltip Component
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const Index = () => {
  const [currentSection, setCurrentSection] = useState('home');
  const [socialLinks, setSocialLinks] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Load theme preference from localStorage or system preference
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setIsDarkMode(storedTheme === 'dark');
    } else {
      setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
    }
  }, []);

  // Save theme preference to localStorag
  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    fetch("/config.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSocialLinks(data.socialLinks);
      })
      .catch((error) => console.error("Error loading config:", error));
 
    const sections = document.querySelectorAll('section');
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  // Social media icons
  const icons = {
    GitHubIcon: GitHubIcon,
    LinkedInIcon: LinkedInIcon,
    YoutubeIcon: YoutubeIcon,
    MailIcon: MailIcon
  };

  // Game images
  const GameImages = [
    { src: require('../images/InTheSands/ITSImage1.png'), alt: 'An image from the Game Nightmares in the sands showcasing a shipwreck in the desert.' },
    { src: require('../images/InTheSands/ITSImage2.png'), alt: 'An image from the Game Nightmares in the sands showcasing the interior of a bedroom.' },
    { src: require('../images/InTheSands/ITSImage3.png'), alt: 'An image from the Game Nightmares in the sands showcasing another angle from the shipwreck in the desert.' },
    { src: require('../images/InTheSands/ITSImage4.png'), alt: 'An image from the Game Nightmares in the sands showcasing another angle from the shipwreck in the desert.' },
    { src: require('../images/InTheSands/ITSImage5.png'), alt: 'An image from the Game Nightmares in the sands showcasing under the shipwreck in the desert.' },
    { src: require('../images/InTheSands/ITSImage6.png'), alt: 'An image from the Game Nightmares in the sands showcasing a computer within the interior of the ship.' },
    { src: require('../images/InTheSands/ITSImage7.png'), alt: 'An image from the Game Nightmares in the sands showcasing a laboratory within the ship.' },
    { src: require('../images/InTheSands/ITSImage8.png'), alt: 'An image from the Game Nightmares in the sands showcasing a pool within the ship.' },
  ];

  // Blockchain images
  const BlockchainImages = [
    { src: require('../images/Chipper/ChipperScreenshot1.png'), alt: 'An image of the Ganache software application.' },
    { src: require('../images/Chipper/ChipperScreenshot2.png'), alt: 'The first screenshot the blockchain React web application showing the profile page.' },
    { src: require('../images/Chipper/ChipperScreenshot3.png'), alt: 'The second screenshot the blockchain React web application showcasing the home page.' },
  ];
  
  // Blockchain Video images
  const BlockchainVideo = {
    type: 'video',
    src: 'https://www.youtube.com/embed/bK14LdVWEgw?rel=0&modestbranding=1&controls=0&autohide=1&iv_load_policy=3',
    title: 'Full Stack Trading System Video',
  };

  const BlockchainItems = [
    ...BlockchainImages,
    BlockchainVideo,
  ];

  const ArduinoImages = [];

  const ArduinoVideo = {
    type: 'video',
    src: 'https://www.youtube.com/embed/oF9YbMF3188?rel=0&modestbranding=1&controls=0&autohide=1&iv_load_policy=3',
    title: 'Arduino Smart Home With Virtual Raspberry Pi',
  };

  const ArduinoItems = [
    ...ArduinoImages,
    ArduinoVideo,
  ];

  return (
    <div className={isDarkMode ? 'dark' : ''}>
      <Header 
        currentSection={currentSection} 
        isHomeSection={currentSection === 'home'} 
        toggleTheme={toggleTheme} 
        isDarkMode={isDarkMode} 
      />
      <section id="home" className={`flex justify-center items-center h-screen ${isDarkMode ? 'bg-black text-white' : 'bg-white text-black'} text-center`}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ type: 'spring', stiffness: 100, damping: 10 }}
        >
          <h1 className="xs:text-5xl sm:text-5xl md:text-7xl lg:text-8xl font-extrabold">Domenic Sesa</h1>
        </motion.div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-96 ">
        <ScrollArrow targetSectionDown="about" targetSectionUp="home" isDarkMode={isDarkMode} />
      </div>

      <section id="about" className="scroll-offset p-8 mt-96">
        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="left">
            <Card>
              <CardHeader>
                <CardTitle>About Me</CardTitle>
                <CardDescription>Some information about me.</CardDescription>
              </CardHeader>
              <CardContent>
                <p>
                  Hi! I'm Dom, a passionate Computer Science student at Swinburne, with a dual major in Artificial Intelligence and Software Development. My enthusiasm for technology extends beyond the classroom as in my spare time I develop games, program, design, and create 3D prints.
                </p>
              </CardContent>
              <CardFooter>
                <p>This site will showcase some projects I have completed and am currently working on.</p>
              </CardFooter>
            </Card>
          </ScrollAnimation>
        </div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-4">
        <ScrollArrow targetSectionDown="projects" targetSectionUp="about" isDarkMode={isDarkMode} />
      </div>

      <section id="projects" className="scroll-offset p-8 mt-96 ">
        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="right">
            <Card className="mb-5">
              <CardHeader>
                <CardTitle>Projects</CardTitle>
                <CardDescription>Here are some projects I have been involved in.</CardDescription>
              </CardHeader>
            </Card>
          </ScrollAnimation>
          <ScrollAnimation from="left">
            <Card>
              <CardHeader>
                <CardTitle>Full Stack, Decentralized Trading System</CardTitle>
                <CardDescription>| HTML5/CSS | JavaScript | Python | React | Solidity | SQL |</CardDescription>
              </CardHeader>
              <CardContent>
                <p>
                  In a team-based environment, our group was able to design and develop a decentralized trading system. This system was implemented utilizing a React.js frontend, providing user interaction through the interface. This frontend was implemented alongside a Python backend for server communications. This solution communicates with the blockchain from Solidity Smart Contracts, allowing assets to be purchased securely.
                </p>
              </CardContent>
            </Card>
          </ScrollAnimation>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-1 mb-10 max-w-6xl mx-auto mt-5">
            <ScrollAnimation from="left">
              <Carousel className="w-full">
                <CarouselContent>
                  {BlockchainItems.map((item, index) => (
                    <CarouselItem key={index} className="w-[300px] h-[700px] flex items-center justify-center">
                      {item.type === 'video' ? (
                        <YoutubeVideo url={item.src} title={item.title} />
                      ) : (
                        <motion.img
                          src={item.src}
                          alt={item.alt}
                          className="w-full max-h-full object-cover object-center"
                        />
                      )}
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </ScrollAnimation>
          </div>
        </div>

        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="left">
            <Card>
              <CardHeader>
                <CardTitle>Arduino Smart Home With Virtual Raspberry Pi</CardTitle>
                <CardDescription>| HTML5/CSS | Python | C++ | SQL | Arduino | Raspberry Pi |</CardDescription>
              </CardHeader>
              <CardContent>
                <p>
                  I designed and developed an IoT Smart Home system as a solo project. 
                  The system uses software and a web app to monitor and control various home components. 
                  It includes a temperature sensor that automatically turns on a DC motor fan at set temperatures, and lights that can be switched on/off and dimmed through a web app. The project involved programming in C++ for the Arduino microcontroller, which communicates with a Raspberry Pi running a Python program and SQL server. This setup manages and adjusts the home's environment based on the collected data.
                </p>
              </CardContent>
            </Card>
          </ScrollAnimation>
        </div>

        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-1 mb-10 max-w-6xl mx-auto mt-5">
            <ScrollAnimation from="right">
              <Carousel className="w-full">
                <CarouselContent>
                  {ArduinoItems.map((item, index) => (
                    <CarouselItem key={index} className="w-[300px] h-[700px] flex items-center justify-center">
                      {item.type === 'video' ? (
                        <YoutubeVideo url={item.src} title={item.title} />
                      ) : (
                        <motion.img
                          src={item.src}
                          alt={item.alt}
                          className="w-full h-full object-cover"
                        />
                      )}
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </ScrollAnimation>
          </div>
        </div>

        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="right">
            <Card>
              <CardHeader>
                <CardTitle>BellaPugCrochet Website</CardTitle>
                <CardDescription>| HTML5/CSS | Next.js | TypeScript | Tailwind |</CardDescription>
              </CardHeader>
              <CardContent>
                <p>Collaborating with a designer, I'm building a stylish and functional website for BellaPugCrochet using Next.js and Tailwind CSS.</p>
                <Button disabled variant="outline" className="mt-4 bg-black text-white border border-white hover:bg-gray-800">
                  BellaPugCrochet Website
                </Button>
              </CardContent>
            </Card>
          </ScrollAnimation>
        </div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-4">
        <ScrollArrow targetSectionDown="games" targetSectionUp="projects" isDarkMode={isDarkMode} />
      </div>
      
      <section id="games" className="scroll-offset p-8 mt-96 ">
        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="left">
            <Card>
              <CardHeader>
                <CardTitle>Game</CardTitle>
                <CardDescription>Nightmares In the Sand</CardDescription>
              </CardHeader>
              <CardContent>
                <p>Created in Unreal Engine 5, I'm developing a First Person Horror game. The images below showcase some of the atmospheric environments within the game.</p>
              </CardContent>
            </Card>
          </ScrollAnimation>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-8xl mx-auto mt-5 mb-10">
          {GameImages.map((image, index) => (
            <ScrollAnimation from="bottom" key={index}>
              <WobbleCard>
                <motion.img src={image.src} alt={image.alt} className="w-full h-full object-cover" />
              </WobbleCard>
            </ScrollAnimation>
          ))}
        </div>

        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="right">
            <Card className=' mb-5'>
              <CardHeader>
                <CardTitle>Game Teaser</CardTitle>
                <CardDescription>Nightmares In the Sand</CardDescription>
              </CardHeader>
              <CardContent>
                <p>Here is a teaser for the game I am currently developing.</p>
              </CardContent>
              <CardFooter>
                <Button disabled variant="outline" className="bg-black text-white border border-white hover:bg-gray-800">
                  Nightmares In the Sand Website
                </Button>
              </CardFooter>
            </Card>
          </ScrollAnimation>
          <YoutubeVideo url="https://www.youtube.com/embed/AUl6jw_lLZQ" title="Nightmares In the Sand Teaser" />
        </div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-4">
        <ScrollArrow targetSectionDown="other" targetSectionUp="games" isDarkMode={isDarkMode} />
      </div>

      <section id="other" className="scroll-offset p-8 mt-96">
        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="left">
            <Card>
              <CardHeader>
                <CardTitle>Other</CardTitle>
                <CardDescription>Other projects I have taken part within.</CardDescription>
              </CardHeader>
              <CardContent>
                <p>Here are some of the 3D prints I have designed and created.</p>
              </CardContent>
              <CardFooter>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-1">
                  <Button 
                    asChild 
                    variant="default" 
                    className="bg-black text-white border border-white hover:bg-white hover:text-black px-12"
                  >
                    <a href="https://www.prusaprinters.org/social/2295220-DomenicSesa">Prusa</a>
                  </Button>
                  <Button 
                    asChild 
                    variant="default" 
                    className="bg-black text-white border border-white hover:bg-white hover:text-black px-12"
                  >
                    <a href="https://www.thingiverse.com/domirox/designs">Thingiverse</a>
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </ScrollAnimation>
        </div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-4">
        <ScrollArrow targetSectionDown="contact" targetSectionUp="other" isDarkMode={isDarkMode} />
      </div>

      <section id="contact" className="p-8 mt-96">
        <div className="container mx-auto max-w-sm sm:max-w-md md:max-w-lg lg:max-w-7xl">
          <ScrollAnimation from="right">
            <Card className='mb-64'>
              <CardHeader>
                <CardTitle>Contact Me / Socials</CardTitle>
                <CardDescription>Other places to find me.</CardDescription>
              </CardHeader>
              <CardContent className="pb-24">
                <div className="grid grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-10">
                  {socialLinks.map((link, index) => {
                    const IconComponent = icons[link.icon];
                    return (
                      <TooltipProvider key={index}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <ButtonCard
                              whileHover={{ scale: 1.05 }}
                              transition={{ type: 'spring', stiffness: 100 }}
                              href={link.href}
                              className="text-center"
                            >
                              <ButtonCardContent>
                                <ButtonCardIcon>
                                  <IconComponent style={{ fontSize: 90 }} />
                                </ButtonCardIcon>
                                <ButtonCardTitle>{link.title}</ButtonCardTitle>
                              </ButtonCardContent>
                              <ButtonCardFooter>
                                <ButtonCardDescription>{link.username}</ButtonCardDescription>
                              </ButtonCardFooter>
                            </ButtonCard>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{link.tooltip}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    );
                  })}
                </div>
              </CardContent>
            </Card>
          </ScrollAnimation>
        </div>
      </section>

      <div className="flex flex-col justify-center items-center w-full mt-4">
        <ScrollArrow targetSectionDown='contact' targetSectionUp="home" isDarkMode={isDarkMode} />
      </div>
      
      <div className="container mx-auto text-center py-5">
        <p>© 2024 Domenic Sesa</p>
      </div>

      <Footer isHomeSection={currentSection === 'home'} isDarkMode={isDarkMode} />
    </div>
  );
};

export default Index;
