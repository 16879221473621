//Name: ScrollAnimation.js
//Author: Domenic Sesa
//Date Created: 24-08-2024

import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const ScrollAnimation = ({ children, from }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.1, 
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const variants = {
    hidden: {
      opacity: 0,
      x: from === 'left' ? -100 : from === 'right' ? 100 : 0,
      y: from === 'up' ? -100 : from === 'down' ? 100 : 0,

    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
  };

  return (
    <motion.div ref={ref} initial="hidden" animate={controls} variants={variants}>
      {children}
    </motion.div>
  );
};

export default ScrollAnimation;
